@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 80px;
  padding: 0, 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
}

nav a {
  margin: 0 2rem;
  color: var(--textColor);
  text-decoration: none;
}

nav a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}
header nav {
  transition: 1s;
}
@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    /* transition: 1s; */
    transform: translateY(-100vh);
    z-index: 999;
  }
  header .responsive_nav {
    transform: none;
    transition: 1s;
  }
  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  nav a {
    font-size: 1.5rem;
  }
  .main__nav__div {
    z-index: 999;
  }
}
@media only screen and (max-width: 678px) {
  header nav {
    transform: translateY(-150vh);
    z-index: 999;
  }
}
