.main__layout {
  height: auto;
  /* display: flex; */
  /* flex-direction: column; */
  /* width: 100vw; */
  margin-bottom: 10px;
}
@media only screen and (max-width: 678px) {
  .main__layout {
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .main__layout {
    width: 100vw;
  }
}
